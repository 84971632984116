import React from 'react';
import CategoryList from '../components/CategoryList';
import ProductCard from '../components/ProductCard';
import { Link } from 'react-router-dom'; // Import Link for navigation

import './HomePage.css';

// Import your images at the top of the file
import heroImage from '../images/futuristicgift.png';  // Adjust the path if necessary
import productImage from '../images/product.png';  // Adjust the path if necessary
import ProductList from '../components/ProductList';
import Footer from '../components/Footer';

const HomePage = () => {
  

  return (
    <div className="home-page">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-text">
          <i>~ India's First step into Future of Gifting</i>
          <h1>We Offer the Best Products for <br />Your Loved One</h1>
          <p>
          Explore our gifting solutions crafted for every occasion. 
          Celebrate love and joy with thoughtful, premium-quality.
          </p>

          <button>
            <Link to="/category/all-products" style={{ textDecoration: 'none', color: 'white' }}>
              Shop Now
            </Link>
          </button>        
          </div>
        <div className="hero-image">
          <img src={heroImage} alt="Futuristic Gift" />
        </div>
      </div>


      <ProductList/>


      {/* Category List */}
      <CategoryList />

   

      <Footer />
    </div>
    
  );
};

export default HomePage;
