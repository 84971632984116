import React from 'react';
import { Link } from 'react-router-dom';
import giftImage from '../images/futuristicgift.png'; // Path to a gift-related image
import Footer from '../components/Footer';
import "./NotFoundPage.css"

const NotFoundPage = () => {
  return (
    <><div className="not-found-page">
          <h2>Oops! It’s a gift mix-up!</h2>
          <img src={giftImage} alt="Gift Box" className="gift-image" />
          <p>The category you're looking for seems to be missing.</p>
          <Link to="/" className="back-home">Back to Home</Link>
      </div><Footer /></>
  );
};

export default NotFoundPage;
