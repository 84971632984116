import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './CategoryList.css';

const categories = [
  { name: 'Trendy Gift', icon: '🎁', path: '/category/trendy' },
  { name: 'Music Gift', icon: '💻', path: '/category/music-gift' },
  { name: 'Good Morning', icon: '🔥', path: '/category/good-morning' },
  { name: 'Good Night', icon: '⭐', path: '/category/good-night' },
  { name: 'Sorry', icon: '🎭', path: '/category/sorry' },
  { name: 'Miss You', icon: '👁️', path: '/category/miss-you' },

];

const CategoryList = () => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  return (
    <>
      <div className="category">
        <i>~ The Categories</i>
        <h2>Browse by Category</h2>
        <hr />
        <br />
      </div>


      <div className="category-list">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-item"
            onClick={() => navigate(category.path)} // Navigate to the specific category path
            style={{ cursor: 'pointer' }} // Add a pointer cursor for better UX
          >
            <span>{category.icon}</span>
            <p>{category.name}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoryList;
