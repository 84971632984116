import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import ProductDetailsPage from './pages/ProductDetailPage';
import CategoryPage from './pages/CategoryPage'; // Create a CategoryPage component for displaying category-specific content
import NotFoundPage from './pages/NotFoundPage';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/category/:category" element={<CategoryPage />} /> {/* Dynamic category route */}
        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all for 404 */}

      </Routes>
    </Router>
  );
};

export default App;
