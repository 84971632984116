import React from 'react';
import { useParams, Link } from 'react-router-dom';
import productImage from '../images/product.png';
import productImg from '../images/futuristicgift.png';
import giftBox from "../images/giftbox.png";
import book1 from "../images/book1.png";
import Footer from '../components/Footer'; // Assuming Footer is in 'components' folder
import './CategoryPage.css';
import heroImage from '../images/futuristicgift.png';  // Adjust the path if necessary


// Define all product categories with their products
const allProducts = {
  trendy: [
    { id: "reason-why-i-love-you", name: 'Reason why I Love You', originalPrice: '₹40', price: '₹29', image: book1, category: 'trendy' },
    { id: "propose-box", name: 'Propose Box', originalPrice: '₹30', price: '₹25', image: giftBox, category: 'trendy' },
    { id: "morning-wish", name: 'Morning Wish', originalPrice: '₹25', price: '₹20', image: productImage, category: 'trendy' },
    { id: "miss-you-pack", name: 'Miss You Pack', originalPrice: '₹60', price: '₹50', image: productImg, category: 'trendy' },
  ],
  'digital-gift': [
    { id: "reason-why-i-love-you", name: 'Reason why I Love You', originalPrice: '₹40', price: '₹29', image: book1, category: 'trendy' },
    { id: "propose-box", name: 'Propose Box', originalPrice: '₹30', price: '₹25', image: giftBox, category: 'trendy' },
  ],
  'on-sale': [
    { id: "discounted-gift-box", name: 'Discounted Gift Box', originalPrice: '₹300', price: '₹250', image: productImage, category: 'ON SALE' },
  ],
  featured: [
    { id: "moisturizer", name: 'Top-Rated Moisturizer', originalPrice: '₹600', price: '₹550', image: productImage, category: 'FEATURED' },
  ],
};

// CategoryPage component
const CategoryPage = () => {
  const { category } = useParams(); // Fetch category from the URL
  const products = allProducts[category] || []; // Get the list of products for the category

  return (
    <>

<div className="hero-section">
        <div className="hero-text">
          <i>~ India's First step into Future of Gifting</i>
          <h1>We Offer the Best Products for <br />Your Loved One</h1>
          </div>
        <div className="hero-image">
          <img src={heroImage} alt="Futuristic Gift" />
        </div>
      </div>
      <div className="product-header">
        <i>~ The Products</i>
        <h2>{category.replace('-', ' ').toUpperCase()}</h2>
        <hr />
      </div>

      <div className="product-list">
        {/* Check if products are available for this category */}
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="product-item">
              <div className="product-box">
                <Link to={`/product/${product.id}`}>
                  <img src={product.image} alt={product.name} />
                </Link>
              </div>
              <p className="product-name">{product.name}</p>
              <p className="product-price">
                <span className="category-tag">{product.category}</span>
                <span className="original-price">{product.originalPrice}</span> {product.price}
              </p>
            </div>
          ))
        ) : (
          <p>No products available for this category.</p>
        )}
      </div>

      <Footer /> {/* Footer component */}
    </>
  );
};

export default CategoryPage;
